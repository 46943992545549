/* eslint-disable camelcase */
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { UPDATE_USER_INFO } from '../data.const';
import { storeUser } from '../data.action';
import { updateUserApi } from '../../../../services/user-service';
import { getUser } from '../data.selector';

const updateUserEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPDATE_USER_INFO),
    withLatestFrom(state$),
    switchMap(([action, state]: any) => {
      const user = getUser(state);
      const payloadToSend = action?.payload;
      return from(updateUserApi({...payloadToSend, id: user.id})).pipe(
        switchMap((data: any) => {
          if (data?.error) {
            throw Error;
          }
          const mappingData = {
            termsConditionAccepted: data.body?.terms_condition_accepted,
            voucher: data.body?.voucher,
          };
          return of(storeUser(mappingData));
        }),
        catchError(err => {
          console.log('entra in error?', err);
          return EMPTY;
        })
      );
    })
  );

export default updateUserEpic;
