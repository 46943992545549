import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of, timer } from 'rxjs';
import * as querystring from 'querystring';
import { GET_DOCUMENTS, POLL_DOCUMENTS_STATUS } from '../data.const';
import {
  getDocumentsSuccess,
  pollDocumentStatus,
  setLoaders,
} from '../data.action';
import {
  getDocumentsList,
  getDocumentStatus,
} from '../../../../services/http/documents.http-service';

const filler = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const epic = (action$: any) =>
  action$.pipe(
    ofType(GET_DOCUMENTS),
    map(({ payload }: any) => payload),
    map(
      ({ page, pageSize }) => `?${querystring.stringify({ page, pageSize })}`
    ),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        timer(0, 5000).pipe(
          takeUntil(action$.pipe(ofType(POLL_DOCUMENTS_STATUS))),
          switchMap(() =>
            from(getDocumentsList(payload)).pipe(
              switchMap((data: any) =>
                from(
                  Promise.all(
                    data?.results?.map(async (el: any, index: number) => {
                      let status = null;
                      status = await getDocumentStatus(el.id);
                      return { ...el, status: status?.reports };
                    })
                  )
                ).pipe(
                  map((results: any) => ({
                    ...data,
                    results: [...results, ...filler],
                  })),
                )
              ),
              switchMap((data: any) => {
                const conditionalActions: any = [];
                if (
                  !data?.results
                    ?.filter((el: any) => el.id)
                    .find(
                      (el: any) => el.status?.classification?.percentage < 100
                    )
                ) {
                  conditionalActions.push(of(pollDocumentStatus()));
                }
                return concat(
                  ...conditionalActions,
                  of(getDocumentsSuccess(data)),
                  of(setLoaders({ documents: false })).pipe(delay(300))
                );
              }),
              catchError(() => EMPTY)
            )
          )
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
  );

export default epic;
