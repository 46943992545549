import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import { DOWNLOAD_DOCUMENTS } from '../data.const';
import {
  downloadDocument,
  downloadDocuments,
} from '../../../../services/http/documents.http-service';
import { setLoaders } from '../data.action';
import { downloadBlob } from '../../../../utils/downloadBlob';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DOWNLOAD_DOCUMENTS),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        // from(downloadDocuments(payload.ids)).pipe(
        //
        //   switchMap(() => EMPTY),
        //   catchError(() => EMPTY)
        // )
        from(payload.elements).pipe(

          concatMap((el: any) =>
            from(downloadDocument(el.id)).pipe(
              switchMap(({ data }: any) => data.blob()),

              tap(blob => {
                if (payload.action === 'visualizza') {
                  window.open(URL.createObjectURL(blob));
                } else {
                  downloadBlob(blob, el.filename);
                }
              }),
              switchMap(() => EMPTY),
              catchError(err => {
                console.warn(err);
                return EMPTY;
              })
            )
          )
        ),
        of(setLoaders({ documents: false }))
      )
    )
  );

export default epic;
