let domain: string;
const appName = 'app';
const prefix = process.env.GATSBY_PREFIX || '';

// eslint-disable-next-line prefer-const
domain = 'https://nk3b0akob6.execute-api.eu-south-1.amazonaws.com'; // PROD: 'https://nk3b0akob6.execute-api.eu-south-1.amazonaws.com';
// domain = process.env.GATSBY_DOMAIN || domain;

const extProvider = '';

export const rootConfig = {
  language: 'it',
  url: `${domain}/api`,
  urlExt: `${extProvider}`,
  app_base_path: `/${appName}`,
  app_path: `${prefix}/${appName}`,
  upload_url: `${domain}/api/uploadAudio.php`,
  audio_folder: `${domain}/api/uploads/audio/`,
  base_url: `${domain}`,
  wp_url: `${domain}/?rest_route=`,
  modalTimeout: 600,
};
