import { ofType } from 'redux-observable';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { RETRIEVE_USER } from '../data.const';
import {  storeUser } from '../data.action';
import {getUserApi} from '../../../../services/user-service'

const getUserEpic = (action$: any) =>
    action$.pipe(
    ofType(RETRIEVE_USER),
       switchMap((payload: {userId: string}) =>{

        const userId = payload?.userId
        return getUserApi(userId)
    }),
      switchMap((data: any) => {
          const mappingData = {
            id: data.body?.id,
            termsConditionAccepted: data.body?.terms_condition_accepted,
            voucher: data.body?.voucher,
          }
         // return EMPTY
         return of(storeUser(mappingData))
    }),
      catchError((err)=> EMPTY)
    )


export default getUserEpic;
