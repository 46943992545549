import { Epic, ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { CHECK_GAME } from '../data.const';
import { retrieveGameUser, setQrCodeValidation } from '../data.action';
import { checkUserGameApi } from '../../../../services/game-service';
import { getUser } from '../data.selector';
import { EnumQrCodeValidation } from '../../../../utils/commons.enum';

const checkValidGameEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CHECK_GAME),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const idUser = getUser(state)?.id;
      const idGioco = action?.payload;
      return from(checkUserGameApi(idUser, idGioco)).pipe(
        switchMap(data => {
          const error= data?.error
          const errorMessage= error?.message
          if (error){
          if (errorMessage === 'qr code already scanned!') {
            return of(
              setQrCodeValidation(EnumQrCodeValidation.ALREADY_SCANNED)
            );
          }
          if (errorMessage === 'something went wrong on scan') {
            return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR));
          }
            return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR));
          }
          return of(
            retrieveGameUser(),
            setQrCodeValidation(EnumQrCodeValidation.SUCCESS)
            );
        }),
        catchError(err => {
          const errorMessage = err?.error?.message       
          if (errorMessage === 'qr code already scanned!') {
            return of(
              setQrCodeValidation(EnumQrCodeValidation.ALREADY_SCANNED)
            );
          }
          if (errorMessage === 'something went wrong on scan') {
            return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR));
          }
          return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR));
        })
      );
    })
  );

export default checkValidGameEpic;
