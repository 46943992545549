/* eslint-disable no-param-reassign */
import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { rootConfig } from '../config/root-config';

const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const getVoucherUserHandler = (idUser: string) =>
  axios(`${rootConfig.base_url}/voucher-user/${idUser}`, {
    method: 'GET',

  }).then((response: any) =>
    response.status === 200
      ? response
      : null
  ).catch(err=> {
    console.log('catch error on check user voucher api', err, err.response);
    return err.response.data
    
  });

const checkUserVoucherApi = (idUser: string, idVoucher: string) =>
axios(`${rootConfig.base_url}/voucher/${idUser}/${idVoucher}`, {
    method: 'POST',

  }).then((response: any) =>
  response.status === 200
    ? response
    : null
).catch(err=> {
  console.log('catch error on check user voucher api', err, err.response);
  return err.response.data
  
});


export {getVoucherUserHandler, checkUserVoucherApi}