export const STATE_KEY = 'data';

export const GET_USER_RELATED_DATA = `${STATE_KEY}/GET_USER_RELATED_DATA`;
export const GET_USER_RELATED_DATA_SUCCESS = `${STATE_KEY}/GET_USER_RELATED_DATA_SUCCESS`;
export const SET_LOADERS = `${STATE_KEY}/SET_LOADERS`;
// create
export const RETRIEVE_USER = `${STATE_KEY}/RETRIEVE_USER`;
export const STORE_USER = `${STATE_KEY}/STORE_USER`;
export const UPDATE_USER_INFO = `${STATE_KEY}/UPDATE_USER_INFO`;
export const RETRIEVE_GAMES = `${STATE_KEY}/RETRIEVE_GAMES`;
export const RETRIEVE_GAME_USER = `${STATE_KEY}/RETRIEVE_GAME_USER`;
export const UPDATE_GAME_USER = `${STATE_KEY}/UPDATE_GAME_USER`;
export const RETRIEVE_VOUCHER = `${STATE_KEY}/RETRIEVE_VOUCHER`;
export const CHECK_GAME = `${STATE_KEY}/CHECK_GAME`;
export const STORE_GAMES = `${STATE_KEY}/STORE_GAMES`;
export const STORE_GAME_USER = `${STATE_KEY}/STORE_GAME_USER`;
export const QR_CODE_VALIDATION = `${STATE_KEY}/QR_CODE_VALIDATION`;
export const CHECK_VOUCHER = `${STATE_KEY}/CHECK_VOUCHER`;
