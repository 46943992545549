/* eslint-disable no-param-reassign */
import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { rootConfig } from '../config/root-config';
import { getItem, setItem, StorageKeys } from './storage-service';
import rootStore from '../state/root-store';
import { logout } from '../state/modules/auth';
import { parseJwt } from '../utils/parseJwt';

const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const login = (data: string) =>
  fetch(`${rootConfig.wp_url}/custom-auth/v1/auth&${data}`, {
    method: 'POST',

  }).then((response: any) =>
    response.status === 200
      ? response.json()
      : { error: true, status: response.status, message: response.statusText }
  );

const loginExt = (data: any) =>
  fetch(`${rootConfig.urlExt}/auth`, {
    method: 'POST',

    body: JSON.stringify(data),
  }).then((response: any) =>
    response.status === 200
      ? response.json()
      : { error: true, status: response.status, message: response.statusText }
  );

const request: any = async ({
  path = '',
  method = httpMethods.GET,
  query = '',
  body,
  headers = {},
  refresh = true,
  noJson = false,
  basePath,
}: any) => {
  let result: any = {};
  let rearm: any = {};
  const user = getItem(StorageKeys.USER);

  const additionalHeaders: any = {};
  const rearmHeaders: any = {};

  if (!basePath) {
    additionalHeaders.jwt = user?.jwt;
    additionalHeaders.username = user?.username;
  } else {
    additionalHeaders.Authorization = `Bearer ${user?.jwt || '--'}`;
  }

  result = await fetch(`${basePath || rootConfig.urlExt}/${path}${query}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...additionalHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  }).then((response: any) => {
    if (response.status === 200) {
      return noJson ? response : response.json();
    }

    return {
      error: true,
      message: 'bad response from server',
      status: response.status,
    };
  });

  if (
    (result.status === 401 || result.status === 403 || result.status === 400) &&
    refresh
  ) {
    rearmHeaders.Authorization = `Bearer ${user?.jwt || '--'}`;
    rearm = await request({
      path: 'custom-auth/v1/auth/refresh',
      method: httpMethods.POST,
      refresh: false,
      basePath: rootConfig.wp_url,
      headers: {
        ...rearmHeaders,
        ...headers,
      },
    }).then((response: any) => {
      if (response.success) {
        return response;
      }

      rootStore.store.dispatch(logout());

      return {
        error: true,
        message: 'unauthorized',
        status: response.status,
      };
    });

    if (rearm.error) {
      result = rearm;
    } else {
      const newUser = {
        jwt: rearm?.data?.jwt,
        ...parseJwt(rearm?.data?.jwt as string),
      };
      setItem(StorageKeys.USER, newUser);

      if (headers.Authorization) {
        delete headers.Authorization;
      }

      result = await request({
        path,
        method,
        query,
        body,
        headers,
        refresh: false,
        basePath,
      });
    }
  }

  return noJson ? { data: result } : { ...result };
};

const fileUpload: any = async ({
  path = '',
  method = httpMethods.POST,
  query = '',
  body,
  headers = {},
  refresh = true,
  files,
}: any) => {
  if (!files || files?.length < 1) {
    return {
      error: true,
      message: 'no files',
      status: 400,
    };
  }

  let result: any = {};
  let rearm: any = {};
  const user = getItem(StorageKeys.USER);
  const additionalHeaders: any = {};
  const rearmHeaders: any = {};

  additionalHeaders.jwt = user?.jwt;
  additionalHeaders.username = user?.username;

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
      ...additionalHeaders,
      ...headers,
    },
  };
  const formData = new FormData();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < files?.length || 0; i++) {
    formData.append('file', files[i]);
  }

  if (body) {
    Object.entries(body).forEach(([key, value]: any) => {
      formData.append(key, value);
    });
  }

  result = await axios
    .post(`${rootConfig.urlExt}/${path}${query}`, formData, config)
    .then(p => p)
    .catch(err => ({
      error: true,
      message: 'error uloading file',
      status: err.statusCode,
    }));

  if (
    (result.status === 401 || result.status === 403 || result.status === 400) &&
    refresh
  ) {
    rearmHeaders.Authorization = `Bearer ${user?.jwt || '--'}`;
    rearm = await request({
      path: 'custom-auth/v1/auth/refresh',
      refresh: false,
      basePath: rootConfig.wp_url,
      headers: {
        ...rearmHeaders,
        ...headers,
      },
    }).then((response: any) => {
      if (response.success) {
        return response;
      }

      rootStore.store.dispatch(logout());

      return {
        error: true,
        message: 'unauthorized',
        status: response.status,
      };
    });

    if (rearm.error) {
      result = rearm;
    } else {
      const newUser = {
        jwt: rearm?.data?.jwt,
        ...parseJwt(rearm?.data?.jwt as string),
      };
      setItem(StorageKeys.USER, newUser);
      result = await fileUpload({
        path,
        method,
        query,
        body,
        headers,
        refresh: false,
        files,
      });
    }
  }

  return { ...result };
};

export { login, loginExt, request, httpMethods, fileUpload };
