import { connect } from 'react-redux';
import Layout from './layout';

import { setPopupInfo } from '../state/modules/data/data.action';

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
  setPopupInfoAction: (payload: any) => dispatch(setPopupInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
