import {
  getItem,
  setItem,
  StorageKeys,
} from '../../../services/storage-service';
import { EnumQrCodeValidation } from '../../../utils/commons.enum';
import {
  SET_LOADERS,
  UPDATE_GAME_USER,
  STORE_USER,
  UPDATE_USER_INFO,
  STORE_GAME_USER,
  STORE_GAMES,
  QR_CODE_VALIDATION,
} from './data.const';

/*
INITIAL STATE
*/

interface State {
  // loaders: {} | null
  user: {
    id: string | null;
    termsConditionAccepted: boolean;
    voucher: boolean;
    starHaier: number;
    starHoover: number;
    starCandy: number;
  };
  qrCodeStatus: EnumQrCodeValidation | undefined;
  games: any; // Da capire
}

const initialState: State = {
  // loaders: {},
  user: {
    id: null,
    termsConditionAccepted: false,
    voucher: false,
    starHaier: 0,
    starHoover: 0,
    starCandy: 0,
  },
  qrCodeStatus: EnumQrCodeValidation.IDLE,
  games: {},
};

/*
  ACTION HANDLERS
  */

const actionHandlers = {
  [SET_LOADERS]: (state: any, payload: any) => ({
    ...state,
    loaders: { ...state.loaders, ...payload } || {},
  }),
  [STORE_USER](state: any, payload: any) {
    const user = { ...state.user, ...payload };
    setItem(StorageKeys.USER, user);
    return { ...state, user };
  },

  [UPDATE_USER_INFO](state: any, payload: { userInfo: any }) {
    return { ...state, user: { ...state.user, ...payload.userInfo } };
  },
  [STORE_GAME_USER](
    state: any,
    payload: { star_haier: number; star_hoover: number; star_candy: number }
  ) {
    return {
      ...state,
      user: {
        ...state.user,
        starHaier: payload.star_haier,
        starHoover: payload.star_hoover,
        starCandy: payload.star_candy,
      },
    };
  },
  [STORE_GAMES](state: any, payload: { games: any }) {
    return { ...state, games: payload.games };
  },
  [UPDATE_GAME_USER](state: any, payload: { gameUser: any }) {
    return { ...state, user: { ...state.user, userGames: payload.gameUser } };
  },
  [QR_CODE_VALIDATION](state: any, payload: EnumQrCodeValidation) {
    return { ...state, qrCodeStatus: payload };
  },
};

/*
REDUCER
*/

const dataReducer = (
  state: any = initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default dataReducer;
