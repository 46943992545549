import * as querystring from 'querystring';
import { fileUpload, httpMethods, request } from '../http-service';

const base = 'documents';

const getDocumentsList = (query: string) =>
  request({ path: `${base}`, method: httpMethods.GET, query });

const getDocumentStatus = (id: string) =>
  request({ path: `${base}/${id}/status`, method: httpMethods.GET });

const uploadDocuments = ({ files, body }: any) =>
  fileUpload({ path: `${base}`, method: httpMethods.POST, files, body });

const setCategory = ({ id, body }: any) =>
  request({ path: `${base}/${id}/category`, method: httpMethods.PUT, body });

const deleteDocument = (id: string) =>
  request({ path: `${base}/${id}`, method: httpMethods.DELETE });

const downloadDocuments = (ids: string[]) => {
  let query = `?`;
  ids?.forEach((el: string, index: number) => {
    query += index === 0 ? `id=${el}` : `&id=${el}`;
  });
  return request({ path: `${base}/download`, method: httpMethods.GET, query });
};

const downloadDocument = (id: string) =>
  request({
    path: `${base}/${id}/view?download=true`,
    method: httpMethods.GET,
    noJson: true,
  });

export {
  getDocumentsList,
  getDocumentStatus,
  uploadDocuments,
  deleteDocument,
  downloadDocuments,
  downloadDocument,
  setCategory,
};
