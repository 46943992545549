/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { rootConfig } from '../config/root-config';

const getUserApi = (userID: string) =>
  fetch(`${rootConfig.base_url}/user/${userID}`, {
    method: 'GET',

  }).then((response: any) =>
    response.status === 200
      ? response.json()
      : { error: true, status: response.status, message: response.statusText }
  );


const updateUserApi = (
 payload: { id: string,
  terms_condition_accepted?: boolean,
  id_gioco?: string,
  id_voucher?: string,
  voucher?: boolean}
) =>
  fetch(`${rootConfig.base_url}/user/${payload.id}`, {
    method: 'PUT',

    body: JSON.stringify(payload)

  }).then((response: any) =>
    response.status === 200
      ? response.json()
      : { error: true, status: response.status, message: response.statusText }
  );


  export {getUserApi, updateUserApi}
