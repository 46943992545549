import { Epic, ofType } from 'redux-observable';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { RETRIEVE_GAME_USER } from '../data.const';
import {  storeUser } from '../data.action';
import {getUserApi} from '../../../../services/user-service'
import { getGameUserHandler } from '../../../../services/game-service';
import { getUser } from '../data.selector';

const getGameUserEpic: Epic = (action$, state$) =>
    action$.pipe(
    ofType(RETRIEVE_GAME_USER),
    tap(()=> console.log('getGameUserEpic')),
    withLatestFrom(state$),
 //   map(({ payload }: any) => payload),
       switchMap(([action, state]) =>{
        const userId = getUser(state)?.id
        return from(getGameUserHandler(userId)).pipe(
          switchMap(data => {
              const mappingData = {
                starHaier: data?.body?.star_haier,
                starHoover: data?.body?.star_hoover,
                starCandy: data?.body?.star_candy,
              }
             return of(storeUser(mappingData))
        }),
          catchError((err)=> EMPTY)
        )
    }),
    )


export default getGameUserEpic;
