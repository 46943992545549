import {
  LOGIN,
  REGISTER,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGOUT,
} from './auth.const';
import { deleteItem, StorageKeys } from '../../../services/storage-service';
import { rootConfig } from '../../../config/root-config';

/*
INITIAL STATE
*/

const initialState = {
  isLoggedIn: null,
  currentUser: 0,
  users: [],
  user: null,
  errorMessage: null,
  authLoading: false,
  apiAction: '',
  authState: undefined,
};

/*
ACTION HANDLERS
*/

const actionHandlers = {
  [LOGIN](state: any) {
    return { ...state, authLoading: true, errorMessage: null };
  },
  [LOGIN_SUCCESS](state: any, payload: { isLoggedIn: any; user: any }) {
    return {
      ...state,
      isLoggedIn: true,
      authState: true,
      authLoading: false,
      errorMessage: null,
      user: payload,
    };
  },
  [LOGIN_ERROR](state: any, payload: string) {
    return {
      ...state,
      isLoggedIn: false,
      authLoading: false,
      errorMessage: payload,
      authState: true,
    };
  },


  [LOGOUT](state: any) {
    deleteItem(StorageKeys.USER);
    return { ...state, isLoggedIn: false, user: initialState.user };
  },
};

/*
REDUCER
*/

const authReducer = (
  state: any = initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default authReducer;
