import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { css, keyframes } from 'styled-components';
import { SortableIcon } from './SortableIcon';

const hoverPrimaryStyle = `box-shadow: 0px 0px 0px 2px var(--brand);`;
const hoverSecondaryStyle = `background-color: #f2f2f2`;
export const menuAnimation = keyframes`
  0% {
    opacity: 0.0;
    transform: scaleY(0);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const theme = deepMerge(grommet, {
  global: {
    colors: {
      active: '#1481BE',
      placeholder: 'dark-1',
      error: '#D8222A',
      greenLeft: '#1481BE',
      greenRight: '#1481BE',
      brand: '#1481BE',
      focus: '#1481BE',
      lightGreen: '#1481BE',
      white: '#ffffff',
      darkGray: '#8A8B8A',
      borderGreen: 'brand',
      background: '#EFF1F4',
      button:'#139BE8',
      menuBg: '#343434',
      menuText: '#B4B5B4',
      searchBar: '#4D4D4D',
      searchBarText: '#B4B5B4',
      pageHead: '#B4B5B4',
      textColor: '#818284',
      textSecondary: '#E7E7E8',
      orange: '#F1AA47',
      black: 'rgb(34,34,34)',
      text: {
        light: 'black',
      },
    },
    // elevation: {
    //   light: {
    //     small: '0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)',
    //     medium: '0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)',
    //     large: '0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)',
    //     xlarge: '0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)',
    //   },
    // },
    font: {
      size: '16px',
      height: '20px',
    },
    input: {
      weight: 200,
    },
    size: {
      avatar: '36px',
      sidebar: '60px',
    },
    drop: {
      extend: css`
        border-radius: 8px;
        min-width: 168px;
        animation: ${menuAnimation} 300ms ease-in-out forwards;
      `,
    },
  },
  icon: {
    size: {
      medium: '18px',
    },
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '20px',
    },
    large: {
      size: '20px',
      height: '24px',
    },
  },
  button: {
    size: {
      small: {
        pad: {
          horizontal: '12px',
          vertical: '6px',
        },
      },
    },
    extend: () => {
      let style = '';
      style += `
        transition: 0.2s ease-in-out;
        font-weight: bold;
      `;
      return style;
    },
    default: grommet.button?.default || {},
    primary: {
      extend: (props: any) => {
        const {
          theme: {
            global: { breakpoints },
          },
        } = props;
        let style = `
          background-color: var(--button);
        `;
        style += `
          @media (min-width: ${breakpoints.small.value}px) {
            &:focus:hover  {
              ${hoverPrimaryStyle}
            }
          }
          &:hover {
            ${hoverPrimaryStyle}
          }
        `;
        return style;
      },
    },
    secondary: {
      extend: (props: any) => {
        const {
          primary,
          theme: {
            global: { breakpoints },
          },
        } = props;
        let style = `

        `;
        style += `
          @media (min-width: ${breakpoints.small.value}px) {
            &:focus:hover  {
              ${hoverSecondaryStyle}
            }
          }
          &:hover {
            ${hoverSecondaryStyle}
          }
        `;
        return style;
      },
    },
  },
  dataTable: {
    header: {
      font: {
        size: 'small',
      },
      color: 'text-strong',
      extend: ({ column, sort, sortable }: any) => `
          ${
            sortable &&
            sort &&
            sort.property !== column &&
            `
              :hover {
                svg {
                  opacity: 100%;
                }
              }
            `
          }
         `,
    },
    icons: {
      sortable: SortableIcon,
    },
  },
  layer: {
    border: {
      radius: 'medium',
      intelligentRounding: true,
    },
  },
  select: {
    options: { text: { size: 'small' } },
    clear: { text: { size: 'small' } },
  },
  menu: {
    // extend: css`
    //   animation: ${menuAnimation} 1s forwards;
    //   transition: none !important;
    // `
  },
  text: {
    medium: {
      size: '16px',
    },
  },
});
