import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, iif, of, timer } from 'rxjs';
import { navigate } from 'gatsby';
import { UPLOAD_DOCUMENTS } from '../data.const';
import { getDocuments, setLoaders, setPopupInfo } from '../data.action';
import {
  setCategory,
  uploadDocuments,
} from '../../../../services/http/documents.http-service';
import { rootConfig } from '../../../../config/root-config';

const epic = (action$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS),
    map(({ payload }: any) => payload),
    //
    // switchMap(() => EMPTY),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ uploadDocuments: true })),
        from(
          uploadDocuments({
            files: payload.files,
            body: { language: rootConfig.language },
          })
        ).pipe(

          switchMap((document: any) =>
            iif(
              () => document?.data?.id && payload?.categoria?.id,
              from(
                setCategory({
                  id: document?.data?.id,
                  body: { id: payload.categoria.id },
                })
              ).pipe(

                switchMap(() => EMPTY),
                catchError(() => EMPTY)
              ),
              EMPTY
            )
          ),
          catchError(() => EMPTY)
        ),
        of(getDocuments({ page: 1, pageSize: 10 })),
        of(setLoaders({ uploadDocuments: false })).pipe(
          delay(300),
          tap(() => navigate(`${rootConfig.app_path}/database/documenti`))
        ),
        of(
          setPopupInfo({
            opened: true,
            message: 'Documento caricato con successo.',
          })
        ),
        timer(3000).pipe(
          switchMap(() =>
            of(
              setPopupInfo({
                opened: false,
                message: '',
              })
            )
          )
        )
      )
    )
  );

export default epic;
