import {
  getUserRelatedDataEpic,
  getCategoriesEpic,
  getDocumentsEpic,
  uploadDocumentsEpic,
  deleteCategoryEpic,
  deleteDocumentsEpic,
  downloadDocumentsEpic,
  getUserEpic,
  getGameUserEpic,
  checkValidGameEpic,
  updateUserEpic,
  checkValidVoucherEpic
} from './epics';

const dataEpics = [
  getUserRelatedDataEpic,
  getCategoriesEpic,
  getDocumentsEpic,
  uploadDocumentsEpic,
  deleteCategoryEpic,
  deleteDocumentsEpic,
  downloadDocumentsEpic,
  getUserEpic,
  getGameUserEpic,
  checkValidGameEpic,
  updateUserEpic,
  checkValidVoucherEpic
];

export default dataEpics;
