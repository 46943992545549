import * as React from 'react';
import { Box, ResponsiveContext, Image } from 'grommet';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { AppLogo } from '../app-logo/app-logo';
import './style.css';
import candyLogo from '../../images/logos/candy.png';
import hooverLogo from '../../images/logos/hoover.png';
import haierLogo from '../../images/logos/haier.png';

const StyledHeader = styled(Box) <any>`
  width: ${props => (props.styleProps?.isSmall ? '100%' : '80%')};
  border-radius: ${props => (props.styleProps?.isSmall ? '0' : '30px')};
  background-color: ${props => props.theme.global.colors?.white};

  position: fixed;
  z-index: 1;
`;

export const AppHeader = ({ actions, ...props }: any) => {
  const size: any = React.useContext(ResponsiveContext);
  const location = useLocation();

  return (
    <StyledHeader
      tag="header"

      styleProps={{
        isSmall: size === 'small' || size === 'medium' || size === 'large',
      }}
      style={{ top: size === 'small' ? '0' : '24px', backgroundColor: "#EFF1F4" }}
    >
      <Box
        flex={false}
        direction="row"
        align="center"
        justify="between"
        responsive={false}
        height="50px"
      >
        <Box
          id="left"
          pad="none"
          direction="row"
          height="100%"
          width="50%"
          justify="center"
          align="center"
        >
          <Image
            style={{ height: 15 }}
            src={candyLogo} />

        </Box>
        <Box
          id="left"
          pad="none"
          direction="row"
          height="100%"
          width="50%"
          justify="center"
          align="center"
        >
          <Image
            style={{ height: 50 }}
            src={hooverLogo} />
        </Box>
        <Box
          id="left"
          pad="none"
          direction="row"
          height="100%"
          width="50%"
          justify="center"
          align="center"
        >
          <Image
            style={{ height: 18 }}
            src={haierLogo} />
        </Box>
      </Box>
    </StyledHeader>
  );
};
