import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import { DELETE_CATEGORY } from '../data.const';
import { getCategories, setLoaders } from '../data.action';
import { deleteCategory } from '../../../../services/http/categorie.http-service';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_CATEGORY),
    map(({ payload }: any) => payload),
    //
    // switchMap(() => EMPTY),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ categories: true })),
        from(deleteCategory(payload)).pipe(
          switchMap(() => of(getCategories(null))),
          catchError(() => EMPTY)
        ),
        of(setLoaders({ categories: false })).pipe(delay(300))
      )
    )
  );

export default epic;
