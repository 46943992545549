import { ofType } from 'redux-observable';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import * as querystring from 'querystring';
import { GET_CATEGORIES } from '../data.const';
import { getCategoriesSuccess, setLoaders } from '../data.action';
import { getCategorieList } from '../../../../services/http/categorie.http-service';
import { rootConfig } from '../../../../config/root-config';

const getCategoriesEpic = (action$: any) =>
  action$.pipe(
    ofType(GET_CATEGORIES),
    map(() => `?${querystring.stringify({ page: 1, pageSize: 100 })}`),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        from(getCategorieList(payload)).pipe(
          map((p: any) => ({
            ...p,
            results: null,
            data:
              p.results?.map((el: any) => {
                const title = el.descriptions?.[rootConfig.language]; // ?.toLowerCase();
                // title = title.charAt(0).toUpperCase() + title.slice(1);
                return {
                  ...el,
                  title,
                };
              }) || [],
          })),
          switchMap(data => of(getCategoriesSuccess(data)))
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
    //

    //
    // switchMap(() => EMPTY),
  );

export default getCategoriesEpic;
