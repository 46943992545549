/* eslint-disable no-param-reassign */
import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { rootConfig } from '../config/root-config';

const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const getGameUserHandler = (idUser: string) =>
  fetch(`${rootConfig.base_url}/game-user/${idUser}`, {
    method: 'GET',

  }).then((response: any) =>
    response.status === 200
      ? response.json()
      : { error: true, status: response.status, message: response.message }
  );

const checkUserGameApi = (idUser: string, idGioco: string) =>
  axios(`${rootConfig.base_url}/game/${idUser}/${idGioco}`, {
    method: 'POST',
  }).then((response: any) =>    
     response.status === 200
      ? response
      : null
  ).catch(err=> {
    console.log('catch error on check user game api', err, err.response);
    return err.response.data
    
  })


export {getGameUserHandler, checkUserGameApi}