import { EnumQrCodeValidation } from '../../../utils/commons.enum';
import {
  RETRIEVE_GAMES,
RETRIEVE_GAME_USER,
RETRIEVE_VOUCHER,
UPDATE_GAME_USER,
GET_USER_RELATED_DATA,
RETRIEVE_USER,
UPDATE_USER_INFO,
STORE_USER,
SET_LOADERS,
CHECK_GAME,
STORE_GAME_USER,
QR_CODE_VALIDATION,
CHECK_VOUCHER
} from './data.const';

/*
ACTIONS
*/

export const getUserRelatedData = (payload: any) => ({
  type: GET_USER_RELATED_DATA,
  payload,
});

export const setLoaders = (payload: any) => ({
  type: SET_LOADERS,
  payload,
});

export const retrieveUser = (userId: string) =>
({ type: RETRIEVE_USER, userId});
export const storeUser = (payload: any) => ({ type: STORE_USER, payload});

export const updateUserInfo = (payload: any) => ({ type: UPDATE_USER_INFO, payload})

export const retrieveGames = () => ({ type: RETRIEVE_GAMES });
export const retrieveGameUser = () => ({ type: RETRIEVE_GAME_USER});
export const storeGameUser = () => ({ type: STORE_GAME_USER});
export const retrieveVoucher = () => ({ type: RETRIEVE_VOUCHER }); // SERVE?

// post/put
export const updateGameUser = () => ({ type: UPDATE_GAME_USER });
export const checkGameQrCode = (payload: any) => ({ type: CHECK_GAME, payload });
export const checkVoucherQrCode = (payload: any) => ({ type: CHECK_VOUCHER, payload });
export const setQrCodeValidation = (payload: EnumQrCodeValidation) => ({ type: QR_CODE_VALIDATION, payload });