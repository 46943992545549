import {
  LOGIN,
  LOGOUT,
  REGISTER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RETRIEVE_AUTH_STATE,
} from './auth.const';

/*
ACTIONS
*/

export const login = (payload: any) => ({ type: LOGIN, payload });
export const logout = () => ({ type: LOGOUT, payload: null });
export const register = (payload: any) => ({ type: REGISTER, payload });
export const loginSuccess = (payload: any) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const loginError = (payload: string | null) => ({
  type: LOGIN_ERROR,
  payload,
});
export const retrieveAuthState = () => ({ type: RETRIEVE_AUTH_STATE });
