import { EMPTY, from, of } from 'rxjs';
import {
  debounceTime,
  map,
  withLatestFrom,
  switchMap,
  tap,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import querystring from 'querystring';
import { LOGIN, RETRIEVE_AUTH_STATE } from './auth.const';
import { login, request } from '../../../services/http-service';
import { loginSuccess, loginError } from './auth.action';
import {
  getItem,
  setItem,
  StorageKeys,
} from '../../../services/storage-service';
import { parseJwt } from '../../../utils/parseJwt';
import { rootConfig } from '../../../config/root-config';

const loginEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(LOGIN),
    withLatestFrom(state$),
    map(([action]) => action?.payload),
    map((p: { username: string; password: string }) => {
      if (p?.username.includes('@')) {
        return { password: p.password, email: p.username };
      }
      return p;
    }),
    map((payload: any) => querystring.stringify(payload)),
    debounceTime(300),
    tap(payload => console.log('[login data]', payload)),
    switchMap((data: any) =>
      from(login(data)).pipe(
        tap(payload => console.log('[login result]', payload)),
        switchMap((response: any) => {
          if (response.error) {
            return of(loginError(response.message));
          }
          const user = {
            jwt: response?.data?.jwt,
            ...parseJwt(response?.data?.jwt as string),
          };
          setItem(StorageKeys.USER, user);
          return of(loginSuccess(user));
        })
      )
    )
  );

const authStateEpic = (action$: any) =>
  action$.pipe(
    ofType(RETRIEVE_AUTH_STATE),
    map(({ payload }) => payload),
    switchMap((jwt: string | null) => {
      const injectedHeaders: any = {};
      if (jwt) {
        injectedHeaders.Authorization = `Bearer ${jwt}`;
      }
      return from(
        request({
          path: 'custom-auth/v1/auth/validate',
          refresh: true,
          basePath: rootConfig.wp_url,
          headers: { ...injectedHeaders },
        })
      ).pipe(
        map(({ data }: any) => ({
          jwt: data?.jwt?.[0]?.token,
          ...data?.user,
          username: data?.user?.user_login,
        })),
        switchMap((user: any) => {
          setItem(StorageKeys.USER, user);
          // const user = getItem(StorageKeys.USER);
          if (!user?.jwt && !user?.username) {
            return of(loginError(null));
          }
          return of(loginSuccess(user));
        }),
        catchError(err => {
          console.log('auth state error', err);
          return EMPTY;
        })
      );
    })
  );

const epics = [loginEpic, authStateEpic];

export default epics;
