import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { GET_USER_RELATED_DATA } from '../data.const';
import { getUserRelatedDataSuccess } from '../data.action';

const mock = {
  tipologie: {
    data: [],
  },
  progetti: [
    {
      id: '1',
      title: 'Progetto 1',
    },
    {
      id: '2',
      title: 'Progetto 2',
    },
    {
      id: '3',
      title: 'Progetto 3',
    },
    {
      id: '4',
      title: 'Progetto 4',
    },
  ],
};

const getUserRelatedDataEpic = (action$: any) =>
  action$.pipe(
    ofType(GET_USER_RELATED_DATA),
    // map(() => `?${querystring.stringify({ page: 1, pageSize: 100 })}`),
    // switchMap((payload: any) => getCategorieList(payload)),
    //
    map(() => mock),
    switchMap(data => of(getUserRelatedDataSuccess(data)))
  );

export default getUserRelatedDataEpic;
