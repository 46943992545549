import { Epic, ofType } from 'redux-observable';
import { catchError,  distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, timer } from 'rxjs';
import { CHECK_VOUCHER } from '../data.const';
import {  setQrCodeValidation } from '../data.action';
import { getQrCodeStatus, getUser } from '../data.selector';
import { EnumQrCodeValidation } from '../../../../utils/commons.enum';
import { checkUserVoucherApi } from '../../../../services/voucher-service';

const checkValidVoucherEpic: Epic = (action$, state$) =>
action$.pipe(
  ofType(CHECK_VOUCHER),
  distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const idUser = getUser(state)?.id
        const qrCodeStatusIdle = getQrCodeStatus(state) === EnumQrCodeValidation.IDLE
        const idVoucher = action?.payload
        if (!qrCodeStatusIdle) {
          return EMPTY
        }
        return from(checkUserVoucherApi(idUser, idVoucher)).pipe(
            switchMap(data => {
            const error = data?.error
            if (error){
              if (error.message === 'voucher qr code already scanned!'){
                return of(setQrCodeValidation(EnumQrCodeValidation.ALREADY_SCANNED))
            }
            if (error.message === 'something went wrong on scan voucher'){
              return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR))
    
            }
            return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR))
    
            }
              console.log('voucher valid!', data);
              return of(setQrCodeValidation(EnumQrCodeValidation.SUCCESS))
        }),
          catchError((err)=> {
              if (err?.error?.message === 'voucher qr code already scanned!'){
                  return of(setQrCodeValidation(EnumQrCodeValidation.ALREADY_SCANNED))
              }
              if (err?.error?.message === 'something went wrong on scan voucher'){
                return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR))
              }
              return of(setQrCodeValidation(EnumQrCodeValidation.GENERIC_ERROR))
            })
        )
    }),
    )


export default checkValidVoucherEpic;
