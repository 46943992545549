import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { concat, EMPTY, from, of } from 'rxjs';
import { DELETE_DOCUMENTS } from '../data.const';
import { getDocuments, setLoaders } from '../data.action';
import { deleteDocument } from '../../../../services/http/documents.http-service';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_DOCUMENTS),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        from(payload.ids).pipe(
          concatMap((id: any) =>
            from(deleteDocument(id)).pipe(
              switchMap(() => EMPTY),
              catchError(() => EMPTY)
            )
          )
        ),
        of(setLoaders({ documents: false })).pipe(delay(300)),
        of(
          getDocuments({
            page: payload.page,
            pageSize: payload.pageSize,
          })
        )
      )
    )
  );

export default epic;
