import * as React from 'react';
import { ReactElement } from 'react';
import { Grommet, Box, Spinner } from 'grommet';
import { Menu } from 'grommet-icons';
import styled from 'styled-components';
import { GlobalStyle } from './style';
import { theme } from '../styles/theme';
import { AppHeader } from '../components/app-header/app-header';
// import { NotificationPopup } from '../components/notification-popup/notification-popup';

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
`;

interface LayoutProps {
  children: ReactElement[] | ReactElement;
  user: any;
  setPopupInfoAction: any;
  popupInfo: any;
}

const Layout = ({
  children,
  user,
  setPopupInfoAction,
  popupInfo,
}: LayoutProps) => (
  <Grommet theme={theme} cssVars style={{ height: '100%' }}>
    <GlobalStyle />

    {/* header */}
    <AppHeader
      appName="FuoriSalone"
      actions={() => console.log("wee")}
      appIcon={<Menu />}
    />

    {children}
    {/* <NotificationPopup
      onPopupClose={() => setPopupInfoAction({ opened: false, message: '' })}
      popupMessage={popupInfo?.message}
      popupOpened={popupInfo?.opened}
    /> */}

    {/* body - end */}
  </Grommet>
);

export default Layout;
